<template>
  <div>
    <div
      class="d-flex flex-row align-items-center justify-content-between mb-8"
    >
      <div class="d-flex flex-row align-items-center">
        <h2 class="font-weight-bold my-2 mr-5">
          Gelir Kalemleri
        </h2>
        <div
          class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"
          style="width: 1px; height:22px;"
        ></div>
        <div class="d-flex align-items-center">
          <span class="text-dark-50 font-weight-bold">{{
            totalItemsText
          }}</span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <button
          class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5"
          @click="$bvModal.show('modal-add')"
        >
          <span class="svg-icon">
            <inline-svg src="media/svg/icons/Code/Plus.svg" />
          </span>
          <span class="d-none d-md-inline">Gelir Kalemi Ekle</span>
        </button>
      </div>
    </div>
    <IncomeItemListings :income-items="incomeItems" @change="onChange" />
    <b-modal
      id="modal-add"
      ref="modal-add"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleModalCancel"
      @hide="handleModalCancel"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="d-flex flex-fill align-items-center justify-content-center pt-2"
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px;"
          >
            <h5>Gelir kalemi ekle</h5>
          </div>
        </div>
      </template>

      <div class="max-h-300px">
        <div class="form-group">
          <label class="text-body font-weight-bold pl-1"
            >Gelir Kaleminin Adı</label
          >
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="modal.itemName"
          />
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="d-flex flex-row align-items-center justify-content-between flex-fill"
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>
          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="modal.itemName.length === 0"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import IncomeItemListings from "@/view/pages/accounting/income-item/components/IncomeItemListings.vue";

export default {
  components: {
    IncomeItemListings
  },
  computed: {
    totalItemsText() {
      return `${this.incomeItems.length} Adet`;
    }
  },
  data() {
    return {
      incomeItems: [],
      modal: {
        itemName: ""
      }
    };
  },
  created() {
    this.fetchIncomeItems();
  },
  methods: {
    fetchIncomeItems() {
      ApiService.get("accounting/income-item/listings.req.php")
        .then(({ data }) => {
          this.incomeItems = [...data.data];
        })
        .catch(({ response }) => {
          this.incomeItems = [];
          alert(response.data.errorMessage);
        });
    },
    onChange() {
      this.fetchIncomeItems();
    },
    handleModalCancel() {
      this.modal = {
        itemName: ""
      };
    },
    handleModalOk() {
      const postData = {
        name: this.modal.itemName
      };
      ApiService.post("accounting/income-item/create.req.php", postData)
        .then(() => {
          this.fetchIncomeItems();
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    }
  }
};
</script>
