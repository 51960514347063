<template>
  <div class="row">
    <div
      v-for="item in incomeItems"
      :key="`income-item__${item.id}`"
      class="col-xl-3 col-lg-6 col-md-6 col-sm-6"
    >
      <IncomeItem
        :id="item.id"
        :name="item.name"
        :average="item.average"
        @editClick="onEditClick"
        @deleteClick="onDeleteClick"
      />
    </div>
    <b-modal
      id="modal-edit"
      ref="modal-edit"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleModalCancel"
      @hide="handleModalCancel"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="d-flex flex-fill align-items-center justify-content-center pt-2"
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px;"
          >
            <h5>Gelir kalemini düzenle</h5>
          </div>
        </div>
      </template>

      <div class="max-h-300px">
        <div class="form-group">
          <label class="text-body font-weight-bold pl-1"
            >Gelir Kaleminin Adı</label
          >
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="modal.itemName"
          />
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="d-flex flex-row align-items-center justify-content-between flex-fill"
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>
          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="modal.itemName.length === 0"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import IncomeItem from "@/view/pages/accounting/income-item/components/IncomeItem.vue";

export default {
  name: "IncomeItemListings",
  props: {
    incomeItems: {
      type: Array,
      default: () => []
    }
  },
  components: {
    IncomeItem
  },
  data() {
    return {
      modal: {
        itemId: null,
        itemName: ""
      }
    };
  },
  methods: {
    onEditClick(itemId) {
      this.modal.itemId = itemId;
      this.modal.itemName = this.incomeItems.find(
        item => item.id === itemId
      ).name;
      this.$bvModal.show("modal-edit");
    },
    onDeleteClick(itemId) {
      const postData = {
        id: itemId
      };
      ApiService.post("accounting/income-item/remove.req.php", postData)
        .then(() => {
          this.$emit("change");
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    },
    handleModalCancel() {
      this.modal = {
        itemId: null,
        itemName: ""
      };
    },
    handleModalOk() {
      const postData = {
        id: this.modal.itemId,
        name: this.modal.itemName
      };
      ApiService.post("accounting/income-item/edit.req.php", postData)
        .then(() => {
          this.$emit("change");
        })
        .catch(({ response }) => {
          alert(response.data.errorMessage);
        });
    }
  }
};
</script>
